import { api } from '../../../lib';
import { MoreFiltersDataItem } from '../../../reducers/gerenciadorPrecos/filters/moreFilters';
import { paramsArrayRepeat } from '../../../utils/paramsArrayRepeat';

export const listMoreFitersData = async (): Promise<MoreFiltersDataItem[]> => {
    return Promise.resolve<MoreFiltersDataItem[]>([{ label: 'Filtro MPDV', value: 'scope' }]);
};

export const listMpdvScopeData = async (search = '') => {
    const response = await api.get<string[]>('/setup/scopes', {
        params: { search },
        expectToken: true,
        paramsSerializer: paramsArrayRepeat,
    });

    return response.data.map((scope) => ({ label: scope, value: scope }));
};
