import classNames from 'classnames';
import React from 'react';
import { CheckPickerProps } from 'rsuite/lib/CheckPicker';
import CheckPill from './CheckPill';
import styles from './CheckPillWithIconProps.module.scss';

type CheckPillWithIconProps = CheckPickerProps & {
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
};

const CheckPillWithIcon = ({ className, startIcon, endIcon, value, ...props }: CheckPillWithIconProps) => {
    return (
        <div className={styles.wrapper}>
            {!value?.length && startIcon && <span className={classNames(styles.icon, styles['start-icon'])}>{startIcon}</span>}
            <CheckPill className={classNames(styles['check-pill'], className)} value={value} {...props} />
            {!value?.length && endIcon && <span className={classNames(styles.icon, styles['end-icon'])}>{endIcon}</span>}
        </div>
    );
};

export default CheckPillWithIcon;
