import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PriceExtraction } from '../../../../../@types/IPA/PriceExtraction';
import { selectorIpaExtractionFiltersValues } from '../../../../../reducers/ipa/extraction/filters/values';
import { selectorExtractionDatapoints, SET_EXTRACTION_DATAPOINTS } from '../../../../../reducers/ipa/extraction/table/datapoints';
import {
    selectorExtractionPagination,
    UPDATE_EXTRACTION_PAGE,
    UPDATE_EXTRACTION_PAGE_SIZE,
    UPDATE_EXTRACTION_TOTAL,
} from '../../../../../reducers/ipa/extraction/table/pagination';
import { checkIfIsLoading } from '../../../../../utils';
import { GET_MODEL_GERENCIADOR_FILTROS, GET_MODEL_GERENCIADOR_PAGINATION } from '../../../RevisaoPrecos/utils';
import { getDatapointsExportacao } from '../../services';

type RowData = PriceExtraction['rowData'];

export const useTable = () => {
    const dispatch = useDispatch();
    const datapoints = useSelector(selectorExtractionDatapoints);
    const value = useSelector(selectorIpaExtractionFiltersValues);
    const pagination = useSelector(selectorExtractionPagination);

    const model = useMemo(
        () => ({
            ...GET_MODEL_GERENCIADOR_FILTROS(value),
            ...GET_MODEL_GERENCIADOR_PAGINATION({
                activePage: pagination.activePage - 1,
                displayLength: pagination.displayLength,
            }),
        }),
        [pagination, value],
    );

    const handleSetDatapoints = useCallback(
        (datapoints: RowData[]) => {
            dispatch(SET_EXTRACTION_DATAPOINTS(datapoints));
        },
        [dispatch],
    );

    const handleChangePage = useCallback(
        (page: number) => {
            dispatch(UPDATE_EXTRACTION_PAGE(page));
        },
        [dispatch],
    );

    const handleChangePageSize = useCallback(
        (size: number) => {
            dispatch(UPDATE_EXTRACTION_PAGE_SIZE(size));
        },
        [dispatch],
    );

    const handleUpdateTotal = useCallback(
        (total: number) => {
            dispatch(UPDATE_EXTRACTION_TOTAL(total));
        },
        [dispatch],
    );

    const handleSuccess = useCallback(
        (data: DatapointRequest<RowData>) => {
            handleSetDatapoints(data.content);
            handleUpdateTotal(data.totalElements);
        },
        [handleSetDatapoints, handleUpdateTotal],
    );

    const { fetchStatus } = useQuery({
        queryKey: ['ipa.extraction.table.datapoints', model],
        queryFn: async () => await getDatapointsExportacao(model),
        onSuccess: handleSuccess,
    });

    return {
        datapoints,
        pagination,
        isLoading: checkIfIsLoading(fetchStatus),
        actions: {
            handleChangePage,
            handleChangePageSize,
        },
    };
};
