import { Dispatch } from 'redux';
import { RESET_FILTER_CACHE, RESET_FILTERS_SEARCH, RESET_FILTERS_VALUE, RESET_SAVED_FILTERS } from '../../../reducers';
import { RESET_MORE_FILTERS } from '../../../reducers/gerenciadorPrecos/filters/moreFilters';
import { RESET_QUICK_ACTION_FILTER } from '../../../reducers/gerenciadorPrecos/quickActionsFilter';

export const resetPriceManagerFilters = (dispatch: Dispatch) => {
    dispatch(RESET_FILTERS_VALUE());
    dispatch(RESET_FILTERS_SEARCH());
    dispatch(RESET_FILTER_CACHE());
    dispatch(RESET_QUICK_ACTION_FILTER());
    dispatch(RESET_MORE_FILTERS());
    dispatch(RESET_SAVED_FILTERS());
};
