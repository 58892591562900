import { useQueries } from '@tanstack/react-query';
import classNames from 'classnames';
import _ from 'lodash';
import React, { ComponentProps, memo, useCallback, useState } from 'react';
import { MdDeleteOutline } from 'react-icons/md';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { RootState } from '../../../@types/RootState';
import { useDebounce } from '../../../hooks';
import {
    CLEAN_MORE_FILTERS_CACHE_RENDER_BY_KEY,
    CLEAN_MORE_FILTERS_RENDER_BY_KEY,
    DELETE_MORE_FILTERS_PILL_BY_VALUE,
    MoreFiltersValue,
    SET_MORE_FILTERS_CACHE_RENDER_BY_KEY,
    SET_MORE_FILTERS_DATA_RENDER_BY_KEY,
    SET_MORE_FILTERS_VALUE_RENDER_BY_KEY,
} from '../../../reducers/gerenciadorPrecos/filters/moreFilters';
import { checkIfIsLoading } from '../../../utils';
import CheckPillWithIcon from '../../FilterGroup/CheckPillWithIconProps';
import { moreFiltersRenderQuery } from '../data/queries';
import styles from './MoreFiltersRender.module.scss';

type MoreFiltersRenderProps = RouteComponentProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<'ul'>;

const MoreFiltersRender = memo(
    ({
        className,
        moreFilters = [],
        data,
        values,
        caches,
        setValueByKey,
        cleanValueByKey,
        deletePillByValue,
        setCacheByKey,
        cleanCacheByKey,
        setDataByKey,
        ...props
    }: MoreFiltersRenderProps) => {
        const debounce = useDebounce();

        const [queries, setQueries] = useState<Partial<Record<MoreFiltersValue, string>>>({});

        const results = useQueries({
            queries: moreFilters.map(({ value }) => {
                return {
                    ...moreFiltersRenderQuery(value, queries[value]),
                    onSuccess: (data: DataItem[]) => {
                        const dataWithCache = [...(caches[value] ?? []), ...data];
                        setDataByKey(value, _.unionBy(dataWithCache, 'value'));
                    },
                };
            }),
        });

        const handleSearch = useCallback(
            (value: MoreFiltersValue) => {
                return (query: string) => {
                    debounce(() => setQueries((prev) => ({ ...prev, [value]: query })));
                };
            },
            [debounce],
        );

        const handleChange = (key: MoreFiltersValue) => {
            return (value: string[]) => {
                setValueByKey(key, value);
                const cache = data[key]?.filter((item) => value.includes(item.value)) ?? [];
                if (cache.length) setCacheByKey(key, cache);
            };
        };

        const handleClean = useCallback(
            (value: MoreFiltersValue) => {
                return () => {
                    cleanValueByKey(value);
                    cleanCacheByKey(value);
                };
            },
            [cleanValueByKey, cleanCacheByKey],
        );

        const handleClose = useCallback(
            (value: MoreFiltersValue) => {
                return () => {
                    handleSearch(value)('');
                };
            },
            [handleSearch],
        );

        const handleClickDelete = useCallback(
            (value: MoreFiltersValue) => {
                return () => {
                    cleanValueByKey(value);
                    deletePillByValue(value);
                };
            },
            [deletePillByValue, cleanValueByKey],
        );

        if (!moreFilters.length) return null;

        return (
            <ul className={classNames(styles['more-filters-render'], className)} {...props}>
                {moreFilters.map(({ value, label }, index) => {
                    const { fetchStatus } = results[index];
                    return (
                        <li key={value} className={styles['more-filters-render__item']}>
                            <CheckPillWithIcon
                                data={data[value] ?? []}
                                value={values[value]}
                                placeholder={label}
                                onSearch={handleSearch(value)}
                                onChange={handleChange(value)}
                                onClean={handleClean(value)}
                                isLoading={checkIfIsLoading(fetchStatus)}
                                endIcon={<MdDeleteOutline size={18} onClick={handleClickDelete(value)} />}
                                onClose={handleClose(value)}
                            />
                        </li>
                    );
                })}
            </ul>
        );
    },
);

MoreFiltersRender.displayName = 'MoreFiltersRender';

const mapStateToProps = (state: RootState) => ({
    moreFilters: state.gerenciadorPrecosReducer.filters.moreFilters.pill,
    values: state.gerenciadorPrecosReducer.filters.moreFilters.render.values,
    caches: state.gerenciadorPrecosReducer.filters.moreFilters.render.caches,
    data: state.gerenciadorPrecosReducer.filters.moreFilters.render.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setValueByKey: (key: MoreFiltersValue, value: string[]) => dispatch(SET_MORE_FILTERS_VALUE_RENDER_BY_KEY({ key, value })),
    setDataByKey: (key: MoreFiltersValue, value: DataItem[]) => dispatch(SET_MORE_FILTERS_DATA_RENDER_BY_KEY({ key, value })),
    setCacheByKey: (key: MoreFiltersValue, value: DataItem[]) => dispatch(SET_MORE_FILTERS_CACHE_RENDER_BY_KEY({ key, value })),
    cleanValueByKey: (key: MoreFiltersValue) => dispatch(CLEAN_MORE_FILTERS_RENDER_BY_KEY(key)),
    cleanCacheByKey: (key: MoreFiltersValue) => dispatch(CLEAN_MORE_FILTERS_CACHE_RENDER_BY_KEY(key)),
    deletePillByValue: (value: MoreFiltersValue) => dispatch(DELETE_MORE_FILTERS_PILL_BY_VALUE(value)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MoreFiltersRender));
