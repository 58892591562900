import classNames from 'classnames';
import React from 'react';
import CheckPicker, { CheckPickerProps } from 'rsuite/lib/CheckPicker';
import styles from './CheckPill.module.scss';

const renderLocale = ({ isLoading, textLoading, textSearch }: Pick<CheckPillProps, 'isLoading' | 'textLoading' | 'textSearch'>) => {
    return {
        noResultsText: isLoading ? textLoading : 'Nenhum item encontrado',
        searchPlaceholder: textSearch,
    };
};

const renderValue = (placeholder?: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Component = (value: any[]) => (
        <div className={styles['placeholder-wrapper']}>
            <p className={styles['placeholder-wrapper__text']}>{value && placeholder}</p>
            <span className={styles['placeholder-wrapper__count']}>{value && value?.length}</span>
        </div>
    );
    return Component;
};

type CheckPillProps = CheckPickerProps & {
    isLoading?: boolean;
    textLoading?: string;
    textSearch?: string;
};

const CheckPill = ({
    className,
    data = [],
    placeholder,
    locale,
    isLoading,
    textLoading = 'Carregando...',
    textSearch = 'Pesquisar',
    ...props
}: CheckPillProps) => {
    return (
        <CheckPicker
            {...props}
            data={isLoading ? [] : data}
            className={classNames(styles['check-picker'], className)}
            renderValue={renderValue(placeholder)}
            placeholder={placeholder}
            locale={renderLocale({
                isLoading,
                textLoading,
                textSearch,
            })}
        />
    );
};

export default CheckPill;
