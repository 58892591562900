import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../@types/RootState';
import { ButtonPrimary } from '../../../../../components';
import { ButtonDropdown } from '../ButtonDropdown';
import useAppyPricesButton from './useAppyPricesButton';

const CTA_BUTTON_ID = 'apply-prices-btn';

const AppyPricesButton = () => {
    const { isDisabled, isDisabledOption, placeholder, handleApplyPrices, isLoading } = useAppyPricesButton();

    const datapointExhibitionType = useSelector((state: RootState) => state.datapointExhibitionType.datapointExhibitionType);

    return (
        <>
            {datapointExhibitionType === 'FAMILY' && (
                <ButtonPrimary disabled={isDisabled || isLoading} onClick={() => handleApplyPrices(datapointExhibitionType === 'FAMILY')} id={CTA_BUTTON_ID}>
                    Aplicar preço
                </ButtonPrimary>
            )}

            {datapointExhibitionType === 'PRODUCT' && (
                <ButtonDropdown
                    disabled={isDisabled || isLoading}
                    onClick={() => handleApplyPrices(datapointExhibitionType === 'PRODUCT' ? false : true)}
                    onSelectOption={(itemId) => {
                        if (itemId === 'APPLY_TO_FAMILY') {
                            handleApplyPrices(datapointExhibitionType === 'PRODUCT');
                        }
                    }}
                    skin="blue"
                    options={[
                        {
                            id: 'APPLY_TO_FAMILY',
                            label: 'Aplicar na Família',
                            value: 'APPLY_TO_FAMILY',
                            disabled: isDisabledOption || isLoading,
                            placeholder,
                        },
                    ]}
                    id={CTA_BUTTON_ID}
                >
                    Aplicar preço
                </ButtonDropdown>
            )}
            {datapointExhibitionType === 'PRODUCT_CLUSTER' && (
                <ButtonPrimary
                    disabled={isDisabled || isLoading}
                    onClick={() => handleApplyPrices(datapointExhibitionType === 'PRODUCT_CLUSTER')}
                    id={CTA_BUTTON_ID}
                >
                    Aplicar preço
                </ButtonPrimary>
            )}
        </>
    );
};

export default memo(AppyPricesButton);
