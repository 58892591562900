import { useQuery } from '@tanstack/react-query';
import React, { memo, useCallback } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { RootState } from '../../../@types/RootState';
import {
    ADD_MORE_FILTERS_PILL,
    MoreFiltersDataItem,
    MoreFiltersValue,
    REMOVE_MORE_FILTERS_PILL,
    RESET_MORE_FILTERS_PILL,
} from '../../../reducers/gerenciadorPrecos/filters/moreFilters';
import { checkIfIsLoading } from '../../../utils';
import { InnerFilter } from '../../FilterSection';
import { moreFiltersDataQuery } from '../data/queries';

type MoreFiltersPillProps = RouteComponentProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const MoreFiltersPill = memo(({ values, addValue, removeValue, resetValue }: MoreFiltersPillProps) => {
    const { data = [], fetchStatus } = useQuery(moreFiltersDataQuery());

    const handleSelect = useCallback(
        (_value: MoreFiltersValue[], item: MoreFiltersDataItem) => {
            const isSelected = values.some(({ value }) => value === item.value);
            if (isSelected) return removeValue(item);
            addValue(item);
        },
        [addValue, removeValue, values],
    );

    return (
        <InnerFilter
            data={data}
            value={values.map((item) => item.value)}
            onSelect={handleSelect}
            isLoading={checkIfIsLoading(fetchStatus)}
            type="check"
            placeholder="+ Filtros"
            searchable={false}
            cleanable
            onClean={resetValue}
        />
    );
});

MoreFiltersPill.displayName = 'MoreFilters';

const mapStateToProps = (state: RootState) => ({
    values: state.gerenciadorPrecosReducer.filters.moreFilters.pill,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addValue: (data: MoreFiltersDataItem) => dispatch(ADD_MORE_FILTERS_PILL(data)),
    removeValue: (data: MoreFiltersDataItem) => dispatch(REMOVE_MORE_FILTERS_PILL(data)),
    resetValue: () => dispatch(RESET_MORE_FILTERS_PILL()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MoreFiltersPill));
