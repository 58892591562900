import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../@types/RootState';

export const moreFiltersValue = ['scope'] as const;

export type MoreFiltersValue = (typeof moreFiltersValue)[number];

export type MoreFiltersDataItem = { label: string; value: MoreFiltersValue };

export type MoreFiltersState = {
    pill: MoreFiltersDataItem[];
    render: {
        data: Partial<Record<MoreFiltersValue, DataItem[]>>;
        caches: Partial<Record<MoreFiltersValue, DataItem[]>>;
        values: Partial<Record<MoreFiltersValue, string[]>>;
    };
};

const initialState: MoreFiltersState = {
    pill: [],
    render: {
        data: {},
        caches: {},
        values: {},
    },
};

const slice = createSlice({
    name: 'ipa.filters.moreFilters',
    initialState,
    reducers: {
        RESET_MORE_FILTERS: () => initialState,
        ADD_MORE_FILTERS_PILL: (state, { payload }: PayloadAction<MoreFiltersDataItem>) => {
            state.pill = [...state.pill, payload];
        },
        REMOVE_MORE_FILTERS_PILL: (state, { payload }: PayloadAction<MoreFiltersDataItem>) => {
            state.pill = state.pill.filter((item) => item.value !== payload.value);
        },
        RESET_MORE_FILTERS_PILL: (state) => {
            state.pill = initialState.pill;
        },
        DELETE_MORE_FILTERS_PILL_BY_VALUE: (state, { payload }: PayloadAction<MoreFiltersValue>) => {
            state.pill = state.pill.filter((item) => item.value !== payload);
        },
        SET_MORE_FILTERS_VALUE_RENDER_BY_KEY: (state, { payload }: PayloadAction<{ key: MoreFiltersValue; value: string[] }>) => {
            state.render.values[payload.key] = payload.value;
        },
        SET_MORE_FILTERS_CACHE_RENDER_BY_KEY: (state, { payload }: PayloadAction<{ key: MoreFiltersValue; value: DataItem[] }>) => {
            state.render.caches[payload.key] = payload.value;
        },
        CLEAN_MORE_FILTERS_RENDER_BY_KEY: (state, { payload }: PayloadAction<MoreFiltersValue>) => {
            state.render.values[payload] = [];
        },
        CLEAN_MORE_FILTERS_CACHE_RENDER_BY_KEY: (state, { payload }: PayloadAction<MoreFiltersValue>) => {
            state.render.caches[payload] = [];
        },
        SET_MORE_FILTERS_DATA_RENDER_BY_KEY: (state, { payload }: PayloadAction<{ key: MoreFiltersValue; value: DataItem[] }>) => {
            state.render.data[payload.key] = payload.value;
        },
    },
});

export const selectorPriceManagerMoreFilters = (state: RootState) => state.gerenciadorPrecosReducer.filters.moreFilters;
export const selectorPriceManagerMoreFiltersValues = (state: RootState) => state.gerenciadorPrecosReducer.filters.moreFilters.render.values;

export const {
    RESET_MORE_FILTERS,
    ADD_MORE_FILTERS_PILL,
    REMOVE_MORE_FILTERS_PILL,
    DELETE_MORE_FILTERS_PILL_BY_VALUE,
    RESET_MORE_FILTERS_PILL,
    SET_MORE_FILTERS_VALUE_RENDER_BY_KEY,
    SET_MORE_FILTERS_CACHE_RENDER_BY_KEY,
    CLEAN_MORE_FILTERS_RENDER_BY_KEY,
    CLEAN_MORE_FILTERS_CACHE_RENDER_BY_KEY,
    SET_MORE_FILTERS_DATA_RENDER_BY_KEY,
} = slice.actions;

export default slice.reducer;
