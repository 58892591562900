import { moreFiltersValue, MoreFiltersValue } from '../../../reducers/gerenciadorPrecos/filters/moreFilters';
import { listMoreFitersData, listMpdvScopeData } from './service';

export const moreFiltersDataQuery = () => {
    return {
        queryKey: ['ipa.priceManager.moreFiltersData'],
        queryFn: listMoreFitersData,
    } as const;
};

export const moreFiltersRenderQuery = (moreFilters: MoreFiltersValue = moreFiltersValue[0], query = '') => {
    return {
        queryKey: ['ipa.moreFiltersRender', moreFilters, query],
        queryFn: async () => {
            const moreFiltersRenderQueryFnMap = new Map<MoreFiltersValue, (query: string) => Promise<DataItem[]>>([['scope', listMpdvScopeData]]);
            const queryFn = moreFiltersRenderQueryFnMap.get(moreFilters);
            return await queryFn?.(query);
        },
        enabled: !!moreFilters,
    };
};
